<template>
  <div>
    <v-card>
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Informations de l'entreprise
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h5></h5>
        <v-text-field
          v-model="bc.name"
          label="Nom de l'entreprise"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.addressLine1"
          label="Addresse"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.postcode"
          label="Code Postal"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.city"
          label="Ville"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.siret"
          label="Numéro Siret"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.phone"
          label="Numéro de téléphone"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.email"
          label="Adresse e-mail"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.person"
          label="Représentant"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Informations de la formation
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <h5>Informations de la formation</h5>
        <v-text-field
          v-model="course.name"
          label="Intitulé du stage"
        >
        </v-text-field>
        <v-text-field
          v-model="course.teacher"
          label="Titre et qualité des formateurs"
        >
        </v-text-field>
        <v-text-field
          v-model="course.dates"
          label="Dates"
        >
        </v-text-field>
        <v-text-field
          v-model="course.duration"
          label="Durée"
        >
        </v-text-field>
        <v-text-field
          v-model="course.location"
          label="Lieu"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Effectifs à former
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row
          v-for="(student, index) in students"
          :key="index"
        >
          <h5>Stagiaire {{ index +1 }}</h5>
          <v-col>
            <v-text-field
              v-model="students[index].firstname"
              label="Prénom"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="students[index].lastname"
              label="Nom"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="students[index].role"
              label="Fonction"
            >
            </v-text-field>
          </v-col>
          <v-btn
            :disabled="index === 0"
            block
            color="error"
            @click="removeStudent(index)"
          >
            Supprimer
          </v-btn>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mt-3"
          block
          color="success"
          @click="addStudent"
        >
          Ajouter
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-5">
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Disposition financières
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text>
        <h5>Ligne du tableau</h5>
        <v-row
          v-for="(singleCourse, index) in courseTable"
          :key="index"
        >
          <v-col>
            <v-text-field
              v-model="courseTable[index].name"
              label="Intitulé"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="courseTable[index].hourlyCost"
              type="number"
              label="Cout unitaire"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="courseTable[index].nbHours"
              type="number"
              label="Nombre d'heures"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="courseTable[index].nbStudents"
              type="number"
              label="Nombre d'apprenant"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              v-model="courseTable[index].total"
              type="number"
              label="Total"
            >
            </v-text-field>
          </v-col>
          <v-btn
            :disabled="index === 0"
            block
            color="error"
            @click="removeCourse(index)"
          >
            Supprimer
          </v-btn>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mt-3"
          block
          color="success"
          @click="addCourse"
        >
          Ajouter
        </v-btn>
      </v-card-actions>

      <v-divider></v-divider>
      <v-card-text>
        <v-text-field
          v-model="course.total"
          label="Soit un total de :"
          suffix="€"
        >
        </v-text-field>
        <v-text-field
          v-model="course.acompte"
          label="Somme versée à titre d'acomptes :"
          suffix="€"
        >
        </v-text-field>
        <v-text-field
          v-model="course.topay"
          label="Somme restant dues"
          suffix="€"
        >
        </v-text-field>
        <v-text-field
          v-model="course.total"
          label="Total général"
          suffix="€"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Cachet entreprise
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text>
        <v-text-field
          v-model="bc.managerName"
          label="Nom du signataire :"
        >
        </v-text-field>
        <v-text-field
          v-model="bc.managerFunction"
          label="Qualité du signataire :"
        >
        </v-text-field>
      </v-card-text>
    </v-card>
    <v-card class="mt-5">
      <v-card-title
        class="d-flex justify-space-between"
        primary-title
      >
        <h4>
          Date et lieu
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiBriefcase }}
        </v-icon>
      </v-card-title>
      <v-divider>
      </v-divider>
      <v-card-text>
        <v-text-field
          v-model="place"
          label="Fait à :"
        >
        </v-text-field>
        <v-text-field
          v-model="date"
          label="Fait le:"
        >
        </v-text-field>
        <v-btn-toggle
          v-model="companySelected"
          tile
          color="primary"
          group
        >
          <v-btn value="cm">
            Call Masther
          </v-btn>

          <v-btn value="mpassionate">
            MPASSIONATE
          </v-btn>
        </v-btn-toggle>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="success"
          @click="generatePdf"
        >
          Générer le PDF
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mdiBriefcase } from '@mdi/js'

export default {
  metaInfo: {
    title: 'Générer une convention',
    titleTemplate: '%s - Jimbo',
  },
  data() {
    return {
      date: '22/02/2023',
      place: 'Clichy',
      bc: {
        name: '',
        addressLine1: '',
        city: '',
        siret: '',
        phone: '',
        email: '',
        person: '',
        managerName: '',
        managerFunction: '',
      },
      course: {
        name: '',
        teacher: '',
        dates: '',
        duration: '',
        location: '',
        total: 0,
        acompte: 0,
        topay: 0,
      },
      students: [{
        firstname: '',
        lastname: '',
        role: '',
      }],
      companySelected: 'cm',
      courseTable: [{
        name: '',
        hourlyCost: 0,
        nbHours: 0,
        nbStudents: 0,
        total: 0,
      }],
      icons: {
        mdiBriefcase,
      },
    }
  },
  methods: {
    addStudent() {
      this.students.push({ firstname: '', lastname: '', role: '' })
    },
    removeCourse(index) {
      this.courseTable.splice(index, 1)
    },
    addCourse() {
      this.courseTable.push({
        label: '',
        nbHours: 0,
        hourlyCost: 0,
        nbStudents: 0,
        total: 0,
      })
    },
    removeStudent(index) {
      this.students.splice(index, 1)
    },
    generatePdf() {
      const data = {
        bc: this.bc,
        students: this.students,
        course: this.course,
        date: this.date,
        place: this.place,
        courseTable: this.courseTable,
        companySelected: this.companySelected,
      }
      console.log(data)
      this.$http.post(`${process.env.VUE_APP_API_URL}/template/generate-convention`, data)
        .then(res => {
          console.log(res)
          window.open(`${process.env.VUE_APP_API_URL}/${res.data}`, '_blank').focus()
        })
        .catch(err => console.log(err))
        .finally()
    },
  },

}
</script>

<style>

</style>
